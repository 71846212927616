import React from 'react'
import { Typography } from 'antd'
import LandingLayout from '@layouts/LandingLayout'
import Helmet from 'react-helmet'

export default () => (
  <LandingLayout>
    <Helmet>
      <title>Admin: Vendors | Proquero [DEV]</title>
      <script src='https://cdn.tryretool.com/embed.js'> </script>
      {/* <script> */}
      {/*  function incrementCompanyId () { */}
      {/*  var d = document.getElementById('companyid'); */}
      {/*  var curValue = parseInt(d.textContent) */}
      {/*  d.textContent = String(curValue + 1) */}
      {/* } */}
      {/* </script> */}
    </Helmet>

    <h1> Company id</h1>
    {/* <p id='companyid'>13</p> */}
    {/* <button onClick='incrementCompanyId()'> increment me</button> */}
    {/* <h1> User id</h1> */}
    {/* <p>1433</p> */}
    {/* <div style='height: 800px; width: 100%;' id='container' /> */}

    <script>
      var rt = window.retool.RetoolDashboard(document.getElementById('container'), 'https://tuscan.retool.com/editor/proquero.com/admin_vendors')
    </script>
  </LandingLayout>
)
